function AnchorLink() {
	function scrollTo(id) {
		const element = document.getElementById(id);
		if (element) {
			element.scrollIntoView({ behavior: 'smooth' });
		}
	}

	// Select all elements with the data-anchor-link attribute
	const anchorNodes = document.querySelectorAll('[data-anchor-link]');

	// Add click event listener to each node
	anchorNodes.forEach(node => {
		document
			.querySelector(`a[href="#${node.id}"]`)
			.addEventListener('click', e => {
				e.preventDefault();
				scrollTo(node.id);
			});
	});
}
export default AnchorLink;
